import React from 'react';
import {Script} from 'react-inline-script';

export const cv = () => {

    return (
        <>
            <Script>
                {
               `
                    setTimeout(() => {
                        document.location.href = '/cv.pdf';
                    }, 1000);
                
                `
            }
            </Script>
        </>
    );
};

export default cv;